// fonts
import "typeface-bitter"          // serif
import "typeface-work-sans"       // sans-serif, wide
import "typeface-special-elite"   // typewriter

// library css
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'swiper/css/swiper.css'

// custom css
import "./src/style/global.scss"
import "./src/style/layout.scss"
import "./src/style/comic-reader.scss"
import "./src/style/comic-promo.scss"
import "./src/style/typography.scss"

// TODO: return false when navigating between pages in same comic series
export const shouldUpdateScroll = ({prevRouterProps, routerProps, getSavedScrollPosition}) => {
  //const currentPosition = getSavedScrollPosition(prevRouterProps.location)
  // TODO: check if only the last path item changed, and if the last path item was just a number
  // TODO: check if we went from series, to page within series (and don't scroll if so)
  // prevRouterProps.location.href: "http://localhost:8000/time-samplers/issue-1-escape-from-jekyll-island/1/"
  // routerProps.location.href: "http://localhost:8000/time-samplers/issue-1-escape-from-jekyll-island/2/"
  //window.scrollTo(currentPosition || [0, 0])

  return true
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}