// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-comic-series-js": () => import("../src/templates/comic-series.js" /* webpackChunkName: "component---src-templates-comic-series-js" */),
  "component---src-templates-comic-issue-js": () => import("../src/templates/comic-issue.js" /* webpackChunkName: "component---src-templates-comic-issue-js" */),
  "component---src-templates-comic-page-js": () => import("../src/templates/comic-page.js" /* webpackChunkName: "component---src-templates-comic-page-js" */),
  "component---src-templates-blog-js": () => import("../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-research-js": () => import("../src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-categories-js": () => import("../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-categories-js": () => import("../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-comics-js": () => import("../src/pages/comics.js" /* webpackChunkName: "component---src-pages-comics-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-research-js": () => import("../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-shop-js": () => import("../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

